import { defineMessages, useIntl } from 'react-intl';

import { Tooltip, TooltipPlacement } from '@jsmdg/yoshi';
import { TooltipVariant } from '@jsmdg/yoshi/dist/enums/tooltipVariant';

type NudgingTooltipProps = {
    readonly isTooltipOpen: boolean;
    readonly onCloseTooltip: () => void;
};

const messages = defineMessages({
    firstLine: {
        defaultMessage:
            'Andere Kunden finden ihr Erlebnis schneller, wenn sie unsere Karte nutzen.',
    },
    secondLine: {
        defaultMessage: 'Probier es doch mal aus!',
    },
});

const NudgingTooltip = ({ isTooltipOpen, onCloseTooltip }: NudgingTooltipProps): JSX.Element => {
    const intl = useIntl();
    return (
        <Tooltip
            placement={TooltipPlacement.Top}
            state={{
                isOpen: isTooltipOpen,
                open: () => {},
                close: () => {},
            }}
            props={{ isOpen: true }}
            variant={TooltipVariant.Nudging}
            onCloseClick={onCloseTooltip}
            tooltipStyles={{ zIndex: 2 }}
        >
            <div className="p-0-5x">
                {intl.formatMessage(messages.firstLine)}
                <br />
                <strong>{intl.formatMessage(messages.secondLine)}</strong>
            </div>
        </Tooltip>
    );
};

export { NudgingTooltip };

export default NudgingTooltip; // eslint-disable-line import/no-default-export
